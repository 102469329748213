@import "../styles/config.less";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins");

*,
*::before,
*::after {
  box-sizing: inherit;
  margin    : 0px;
  padding   : 0px;
}

body {
  font-family: @font-family  !important;
}

code {
  font-family: @font-family  !important;
}

.missing{
  color: red;
}

:root {
  --font-size-huge        : @font-size-huge;
  --font-size-massive     : @font-size-massive;
  --font-size-extralarge  : @font-size-extralarge;
  --font-size-large       : @font-size-large;
  --font-size-big         : @font-size-big;
  --font-size-medium      : @font-size-medium;
  --font-size-default     : @font-size-default;
  --font-size-small       : @font-size-small;
}

html {
  font-family: @font-family !important;
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
strong {
  margin : 0;
  padding: 0;
  border : 0;
}
