.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.p-checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.p-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.13em;
    width: 1.13em;
    /* background-color: var(--four); */
    border: 1px solid var(--fourty);
    border-radius: 2px;
}
.p-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.13em;
    width: 1.13em;
    /* background-color: var(--four); */
    border: 1px solid var(--fourty);
    border-radius: 2px;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: var(--white);
    border: 1px solid var(--twentytwo);
}
.p-checkbox-container:hover input ~ .p-checkmark {
    background-color: var(--white);
    border: 1px solid var(--twentytwo);
}
/* When the checkbox is checked, add a background */
.checkbox-container input:checked ~ .checkmark {
    background-color: var(--twentytwo);
}
.p-checkbox-container input:checked ~ .p-checkmark {
    background-color: var(--twentytwo);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}
.p-checkmark:after {
    content: '';
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}
.p-checkbox-container input:checked ~ .p-checkmark:after {
    display: block;
}
/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 5px;
    width: 3px;
    top: 1px;
    height: 8px;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.p-checkbox-container .p-checkmark:after {
    left: 5px;
    width: 3px;
    top: 1px;
    height: 8px;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* tool tip */
.tool-tip-container {
    display: flex;
    position: fixed;
    z-index: 100;
}
.tool-tip-text {
    background-color: #000000b3;
    color: var(--white);
    padding: 4px;
    border-radius: 6px;
    font-weight: normal;
}

.tool-tip-sec-text {
    background-color: #111643;
    color: var(--white);
    padding: 4px;
    border-radius: 6px;
    font-weight: normal;
}
/* tool tip */
