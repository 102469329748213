@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
// @import url("https://fonts.googleapis.com/css?family=Poppins"); 
@import url("../src/assets/styles/config.less");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
    /* green */
    --primary       : #2aa076;
    --primary-light : #e6f1f7;

    --num-cards     : 2;
    --feed-gap      : 1.125rem;
    --card-height   : 20.313rem;
    --one           : #083B46;
    --two           : #f1f1f1;
    --three         : #ffffff;
    --four          : #f0f0f0;
    --five          : #d4d4d459;
    --six           : #f5f5f5;
    --seven         : #000000;
    --eight         : #4abcff;
    --nine          : #e6234a;
    --ten           : #f7f7f7;
    --twelve        : #d6d6d6;
    --fourteen      : #8898aa;
    --thirteen      : #d4d4d4;
    /* battleship-grey */
    --fifteen       : #686f7a;
    --sixteen       : #a59d9d;
    --seventeen     : #e6e6e6;
    --eighteen      : #ffbd25;
    --nineteen      : #f2f2f2;
    --twenty        : #f2f3f5;
    --twentyone     : #454b55;
    --twentytwo     : #007791;
    --twentythree   : #f5a623;
    --twentyfour    : #e6f2f5;
    --twentyfive    : #0765c6;
    --twentysix     : #e8e8e8;
    --twentyseven   : #f9f9f9;
    --twentyeight   : #c5c5c5;
    --twentynine    : #ff0739;
    --thirty        : #f0f0f7;
    --thirtyone     : #ffce00;
    --thirtytwo     : #00b9ff;
    --thirtythree   : #dd731d;
    --thirtyfour    : #393e46;
    --thirtyfive    : #24a2ec;
    --thirtysix     : #707070;
    --thirtyseven   : #d5d5d5;
    --thirtyeight   : #dedfe0;
    --white         : #fff;
    --orange        : #ff6c00;
    --iconBlue      : #00c2c3;
    --iconGrey      : #c3c3c3;
    --camIcon       : #6ea1b3;
    --btnSecondary  : #8798ab;
    --inputB        : #7c7c7c;
    --fifty         : #e5e5e5;
    --fiftyone      : #f6f6f6;
    --progressFF    : #30c093;
    --btnBlue       : #4c6eb4;
    --thirtynine    : #4a4a4a;
    --fourty        : #cacbcc;
    --fourtyone     : #636363;
    --fourtytwo     : #bdc3c7;
    --fourtythree   : #3456FF; //#e4b800;
    --fourtyfour    : #f3f3f3;
    --fourtyFive    : #757575;
    --choclateorange: #f77a52;
    --blackgrey     : #363636;
    --bluegreen     : #e2e3e3;
    --fourtysix     : #bababa;
    --fourtyseven   : #5e5e5e;
    --fourtyeight   : #aaaaaa;
    --fourtynine    : #14171c;
    --fiftytwo      : #7460ee;
    --fiftythree    : #e5f7fe;
    --darkgreen     : #6bba70;
    --darkgreentrans: #d2ead4;
    --greycardbg    : #f8f8f8;
    --greycardborder: #dfdfdf;
    --warnred       : #ff0000;
    --tableHeader   : #5d83d8;
    --roundBg       : #0078d71a;
    --mildYellow    : #ffd78a;
    --mildBlue      : #84eaff;
    --fiftyfour     : #484848;
    --fiftyfive     : #f4f4f4;
    --fiftysix      : #e4e4e4;
    --fiftyseven    : #3fbbc6;
    --fiftyEight    : #eafafa;
    --fiftyNine     : #898989;
    --sixty         : #94989a;
    --sixtyOne      : #1492e6;
    --sixtyTwo      : #5a5a5a;
    --sixtythree    : #808080;
    --sixtyFour     : #bbbbbb;
    --sixtyFive     : #787878;
    --sixtySix      : #505050;
    --sixtySeven    : #0078d7;
    --sixtyEight    : #818181;
    --progressGreen : #5bb85d;
    --progressOrange: #efad4d;
    --progressRed   : #d9544f;
    --sixtyNine     : #7467f0;
    --seventy       : #ffa550;
    --seventyOne    : #2699fb;
    --seventyTwo    : #ea6061;
    --seventyThree  : #15b215;
    --seventyFour   : #2ba400;
    --seventyFive   : #f36323;
    --seventySix    : #a3a0fb;
    --seventySeven  : #54d8ff;
    --seventyEight  : #b7b7b7;
    --seventyNine   : #34bf49;
    --eighty        : #00cfa8;
    --eightyOne     : #cccccc;
    --eightyTwo     : #303674;
    --eightythree   : #ed5e68;
    --chart-color1  : #55BA45;
    --chart-color2  : #F73C4F;
    --chart-color3  : #FFAD3A;
}

.p-sidebar {
    background: #E6F1F7 !important;
}

.test-sidebar::-webkit-scrollbar {
    width : 4px !important;
    height: 4px !important;
}

.section-panel-scroll {
    scroll-behavior: smooth;
}

@media (min-width:641px) {

    /* custom scroll bar */
    *::-webkit-scrollbar {
        width : 6px !important;
        height: 6px !important;
    }

    *::-webkit-scrollbar-track {
        background-color: transparent !important;
    }

    *::-webkit-scrollbar-thumb {
        border-radius   : 10px !important;
        background-color: #D6D6D6 !important;
    }
}


* {
    scrollbar-width            : thin;
    -moz-appearance            : initial;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.bg-primary-light{
    background-color: #e6f1f7;
}

.bg-primary{
    background-color: #2AA076;
}

input,
textarea,
I button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:focus,
a:visited,
a:active {
    outline: none;
}

html {
    height: 100%;
}

body {
    font-family: 'Poppins', sans-serif;
    margin     : 0;
    height     : 100%;
    background : var(--white);
    line-height: 1.4;
}

p {
    margin-block-start: auto;
    margin-block-end  : auto;
}

img,
video {
    max-width: 100%;
}

.scrollbar-gutter {
    scrollbar-gutter: stable;
}

.trans-scroll::-webkit-scrollbar {
    width: 0 !important;
}

.trans-scroll:hover::-webkit-scrollbar-thumb {
    background-color: transparent !important;
}

.trans-scroll::-webkit-scrollbar-thumb {
    background-color: transparent !important;
}

*:focus {
    outline: none;
}

.grecaptcha-badge {
    display: none !important;
    opacity: 0;
}

.font10 {
    font-size: var(--font-size-small);
}

.font11 {
    font-size: 11px;
}

.font12 {
    font-size: var(--font-size-default);
}

.font13 {
    font-size: 13px;
}

.font14 {
    font-size: var(--font-size-medium);
}

.font16 {
    font-size: var(--font-size-big);
}

.font15 {
    font-size: 15px;
}

.font20 {
    font-size: var(--font-size-extralarge);
}

.font22 {
    font-size: 22px;
}

.font18 {
    font-size: var(--font-size-large);
}

.font40 {
    font-size: 40px;
}

.fullFill {
    height: 100vh;
}

.font24 {
    font-size: var(--font-size-massive);
}

.font26 {
    font-size: 26px;
}

.font28 {
    font-size: 28px;
}

.paginationArea {
    position       : fixed;
    display        : flex;
    width          : 100%;
    bottom         : 0;
    left           : 0;
    box-shadow     : 2px 2px 10px 0 rgba(0, 0, 0, 0.16);
    align-items    : center;
    justify-content: center;
    background     : var(--three);
}

.currenttab {
    border-right : 3px solid transparent !important;
    border-left  : 3px solid transparent !important;
    border-bottom: 3px solid #3456FF !important;
    background   : linear-gradient(360deg, rgba(52, 86, 255, 0.4) -134.62%, rgba(52, 86, 255, 0) 100%);
}

.instruction-list {
    list-style: inside !important;
}

.hide_button {
    display: none;
}


.ace-monokai .ace_gutter {
    background: #1E2431;
    z-index   : 0;
}

.ace-monokai .ace_scroller {
    background: #060D1A;
}

.ace-monokai .ace_gutter {
    color: #666666;
}

@media screen and (max-width: 1024px) {
    .font11 {
        font-size: 10px;
    }

    .font12 {
        font-size: 11px;
    }

    .font13 {
        font-size: 12px;
    }

    .font14 {
        font-size: 13px;
    }

    .font16 {
        font-size: 14px;
    }

    .font20 {
        font-size: 18px;
    }

    .font22 {
        font-size: 20px;
    }

    .font26 {
        font-size: 24px;
    }

    .font24 {
        font-size: 22px;
    }

    .font28 {
        font-size: 28px;
    }

    .font18 {
        font-size: 16px;
    }

    .font40 {
        font-size: 32px;
    }
}

@media screen and (max-width: 480px) {
    .font11 {
        font-size: 9px;
    }

    .font12 {
        font-size: 10px;
    }

    .font13 {
        font-size: 11px;
    }

    .font14 {
        font-size: 12px;
    }

    .font16 {
        font-size: 13px;
    }

    .font20 {
        font-size: 18px;
    }

    .font22 {
        font-size: 17px;
    }

    .font26 {
        font-size: 20px;
    }

    .font24 {
        font-size: 18px;
    }

    .font28 {
        font-size: 24px;
    }

    .font18 {
        font-size: 15px;
    }

    .font40 {
        font-size: 26px;
    }
}

.oddBorder {
    background: var(--fourtyfour);
}

.ctitle {
    background-color: #ffffff;
    color           : #000000;
    font-size       : var(--font-size-default);
    font-weight     : 600;
    border-bottom   : 1px solid #C3C3C3;
    display         : flex;
    justify-content : space-evenly;
    padding         : 0 20px;
    align-items     : center;
    position        : relative;
    height          : 61px;
}

input[type='checkbox'] {
    -moz-appearance: checkbox;
}

input[type='password']::-webkit-credentials-auto-fill-button {
    visibility    : hidden;
    pointer-events: none;
    position      : absolute;
    right         : 0;
}

.colorY {
    color: red;
}

.tc-table-cont .icon-close-1:before {
    color    : var(--warnred);
    font-size: 14px;
}

.tc-table-cont .icon-ios-checkmark:before {
    color    : var(--darkgreen);
    font-size: 18px;
}

.test-submit-success .icon-ios-checkmark:before {
    color    : #2E8540;
    font-size: 18px;
}

.test-submit-success .icon-alert-o:before {
    color    : #666666;
    font-size: 16px;
}

.tc-table-cont .icon-alert:before {
    color    : var(--thirtyone);
    font-size: 18px;
}

/* drive related reused css */
.countC {
    display        : flex;
    justify-content: space-between;
    padding        : 25px 40px;
    align-items    : center;
    background     : var(--six);
}

.driveTabsC {
    width       : 75%;
    border-right: 2px solid var(--six);
    padding-top : 25px;
    position    : relative;
}

.driveSecC {
    display: flex;
    width  : 100%;
}

.driveProfileSalary {
    width        : 80%;
    padding      : 0% 10%;
    text-align   : center;
    margin-bottom: 10px;
}

.backIcon {
    cursor: pointer;
}

/* .additionalDetails
{
    font-size: 22px;
    font-weight: 500;
    display: inline-block;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 5px;
} */

/* .profileHeader
{
    font-size: 22px;
    font-weight: 500;
    display: inline-block;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 5px;
}
.profileHeader:before
{
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 25%;
    border-bottom: 1px solid #333;
} */
.profileset {
    text-align: left;
    padding   : 0% 5%;
    width     : 90%;
}

.profileSetHeader {
    font-size     : 16px;
    font-weight   : 500;
    display       : inline-block;
    padding-bottom: 10px;
    position      : relative;
    text-align    : right;
    width         : 46%;
}

.profileSetSalary {
    font-size     : 16px;
    display       : inline-block;
    position      : relative;
    text-align    : left;
    width         : 50%;
    padding       : 0% 2%;
    padding-bottom: 10px;
}

.labelheader {
    font-size : 14px;
    width     : 100%;
    text-align: center;
}

.profiletable {
    width: 100%;
}

.profiletable tbody tr:nth-child(even) {
    background-color: #f2f2f2;
    min-height      : 50px;
}

.profiletable-header {
    margin        : 0 auto;
    width         : 100%;
    background    : var(--tableHeader);
    color         : var(--white);
    text-transform: capitalize;
    padding       : 5% 2%;
    height        : 40px;
    font-size     : 16px;
    border        : none;
}

.overflowwrap {
    overflow-wrap: break-word;
    padding      : 1px 1px;
}

.labelstable {
    width        : 100%;
    margin-bottom: 25px;
}

.labels {
    width     : 50%;
    text-align: right;
}

.driveLogsC {
    width       : 25%;
    padding     : 25px 10px;
    padding-left: 20px;
    background  : var(--white);
}

.transZ {
    transform: scale(1.5);
    position : relative;
    right    : 45px;
}

.driveValdgreen {
    background  : var(--one) !important;
    border-right: 1px solid #399834 !important;
}

.drivegreen::after {
    border-left-color: var(--one) !important;
}

.driveValdred {
    background  : #ff0000 !important;
    border-right: 1px solid #ff0000 !important;
}

.noselect {
    user-select: none;
}

.printablePDF {
    display: block;
    position:  inherit !important;
    height: 518px !important;
}

.printablePDF1 {
    display: block;
    position:  inherit !important;
}

.drivered::after {
    border-left-color: #ff0000 !important;
}

.driveValdbrown {
    background  : #a74e5c !important;
    border-right: 1px solid #a74e5c !important;
}

.drivebrown::after {
    border-left-color: #a74e5c !important;
}

.youtube .play-button {
    width           : 90px;
    height          : 60px;
    background-color: #333;
    box-shadow      : 0 0 30px #0000;
    z-index         : 1;
    opacity         : 0.8;
    border-radius   : 6px;
}

.youtube .play-button:before {
    content     : '';
    border-style: solid;
    border-width: 15px 0 15px 26px;
    border-color: transparent transparent transparent var(--fifteen);
}

.youtube img,
.youtube .play-button {
    cursor: pointer;
}

.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
    position: absolute;
}

.currenttab {
    // border-right : 3px solid transparent !important;
    // border-left  : 3px solid transparent !important;
    border-bottom: 3px solid #3456FF !important;
    background   : linear-gradient(360deg, rgba(52, 86, 255, 0.4) -134.62%, rgba(52, 86, 255, 0) 100%);
}

.youtube .play-button,
.youtube .play-button:before {
    top      : 50%;
    left     : 50%;
    z-index  : 1;
    transform: translate3d(-50%, -50%, 0);
}

@media screen and (max-width: 993px) {
    .transZ {
        transform: scale(1.2);
        position : relative;
        right    : 25px;
    }

    .countC {
        padding: 25px 10px;
    }

    .driveVal {
        width: auto;
    }

    .fullScreenPrintablePDF .ng2-pdf-viewer-container {
        height: 600px;
    }
}

.w_500 {
    font-weight: 500;
}

.pdfQPUpload .ng2-pdf-viewer-container::-webkit-scrollbar {
    width : 0px;
    height: 0px;
}

.printablePDF .ng2-pdf-viewer-container {
    height    : 550px;
    overflow-x: hidden !important;
}


.printablePDF1 .ng2-pdf-viewer-container {
    overflow-x: hidden !important;
    position: unset;
}

.previewPDF .ng2-pdf-viewer-container {
    height    : 550px;
    overflow-x: scroll !important;
}

.printablePDFRA .ng2-pdf-viewer-container {
    height    : 600px;
    overflow-x: hidden !important;
}

.fullScreenPrintablePDF .ng2-pdf-viewer-container {
    height    : 700px;
    overflow-x: hidden !important;
}

.pdfFormFill .ng2-pdf-viewer-container {
    height: 550px;
}

@media print {
    .previewPDF {
        display: none;
    }
}

.previewPDF {
    display: block;
}

@media screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .printablePDF .ng2-pdf-viewer-container {
        height: 440px;
    }

    .previewPDF .ng2-pdf-viewer-container {
        height: 440px;
    }

    .pdfFormFill .ng2-pdf-viewer-container {
        height: 440px;
    }

    .ql-snow.ql-toolbar button {
        padding: @px-3 0 !important;
        width  : @px-20 + @px-5  !important;
    }

    .ql-toolbar.ql-snow .ql-formats {
        flex-wrap: wrap;
    }
}

a.spelling_mistake {
    color          : @error-color;
    text-decoration: none;
}

a.grammar_mistake {
    color          : @success-color;
    text-decoration: none;
}

.ace_tooltip {
    white-space: break-spaces;
}

@keyframes bouncing-loader {
    to {
        opacity  : 0.1;
        transform: translate3d(0, -8px, 0);
    }
}

.bouncing-loader {
    display        : flex;
    justify-content: end;
    margin-left    : 18px;
}

.bouncing-loader>div {
    width        : 8px;
    height       : 8px;
    margin       : 2px;
    background   : var(--blackgrey);
    border-radius: 50%;
    animation    : bouncing-loader 0.8s infinite alternate;
}

.bouncing-loader>div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader>div:nth-child(3) {
    animation-delay: 0.4s;
}

.message ol {
    padding: 0;
}

.message ol li {
    list-style   : none;
    font-weight  : 400;
    font-size    : var(--font-size-medium);
    line-height  : 21.28px;
    color        : #666666;
    margin-bottom: 20px;
    display      : flex;
    align-items  : flex-start;
}

.message ol li .instruction-points {
    margin-right: 10px;
    margin-top  : 3px;
    height: 16px;
    width: 16px;
    transform: rotate(45deg);

}

.browser-support {
    margin-top: 25px;
}

.browser-support .browser-list {
    margin-bottom: 10px;
}

.browser-support .browser-list .browser-icon {
    height      : 20px;
    width       : 20px;
    margin-right: 10px;
}

.browser-list>a {
    font-size      : var(--font-size-default);
    color          : #083B46;
    font-weight    : 600;
    text-decoration: none;
}

.blocklySvg {
    width: 100%;
}

.injectionDiv {
    border-radius: 7px;
}

.blocklyToolboxDiv {
    background-color: #F5F7FA;
}

.blocklyMainBackground {
    stroke: none;
}

.injectionDiv,
.blocklyTrash,
.blocklyScrollbarVertical,
.blocklyScrollbarHorizontal,
.blocklyFlyout {
    z-index: 0;
}

svg[display="none"] {
    display: none;
}

.searchFilter:hover {
    cursor: pointer;
}

.openide-footer {
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.11);
}

.notViewedBg {
    border-color: #666666;
    background  : linear-gradient(360deg, rgba(247, 249, 252, 0.6) 0%, rgba(247, 249, 252, 0.01) 100%);
}

.answeredBg {
    border-color: #55BA45;
    background  : linear-gradient(0deg, rgba(85, 186, 69, 0.1) 0%, rgba(85, 186, 69, 0) 110.47%);
}

.skippedBg {
    border-color: #FF5E5B;
    background  : linear-gradient(0deg, rgba(255, 94, 91, 0.1) 0%, rgba(242, 96, 56, 0.1) 0.01%, rgba(255, 94, 91, 0) 101.36%);
}

.bookmarkedBg {
    border-color: #FFAD3A;
    background  : linear-gradient(0deg, rgba(255, 173, 58, 0.1) -3.19%, rgba(225, 203, 255, 0.0106251) 99.99%, rgba(255, 173, 58, 0) 100%);
}

.courseTypeBg {
    background: linear-gradient(86.04deg, rgba(50, 107, 238, 0.26) -53.2%, rgba(160, 186, 246, 0.114582) 15.46%, rgba(247, 249, 252, 0) 162.88%);
}

.selectedMenuItem {
    background: linear-gradient(90deg, #3456FF 1.75%, rgba(52, 86, 255, 0.6) 102.4%);
}

.p-inputtext {
    padding-left : 0.75rem !important;
    white-space  : nowrap !important;
    overflow     : hidden !important;
    text-overflow: ellipsis !important;
}

.pi-angle-down:before {
    content               : "\e930";
    font-family           : 'primeicons', sans-serif;
    font-style            : normal;
    font-weight           : normal;
    font-feature-settings : normal;
    font-variant          : normal;
    text-transform        : none;
    line-height           : 1;
    display               : inline-block;
    -webkit-font-smoothing: antialiased;
}

.languageDropdown .p-dropdown {
    padding: 10px !important;
}

.programmingDropdown .p-dropdown {
    background: none !important;
    border    : none !important;
}

.programmingDropdown .p-dropdown .p-dropdown-label {
    color  : #ffffff !important;
    padding: 0px !important;
}

.titleHeader {
    padding: 15px;
}

.eachViewFields {
    width : 48%;
    margin: 5px;
}

.padding-0 {
    padding: 0% !important;
}

.width-100 {
    width: 100% !important;
}

@media screen and (max-device-width: 480px) {
    .eachViewFields {
        width : 100%;
        margin: 5px;
    }

    .fieldList {
        margin: 10px 10px 0 10px;
    }

    .driveAdditonalDetails {
        width        : 80%;
        padding      : 0% 5%;
        text-align   : left;
        margin-bottom: 25px;
    }

    .profiletable-header th {
        max-width  : 50px;
        font-size  : 16px;
        font-weight: 400;
    }

    .profiletable tbody tr td {
        height   : 50px;
        max-width: 50px;
    }
}

@media screen and (min-device-width: 480px) {
    .eachViewFields {
        width : 48%;
        margin: 5px;
    }

    .fieldList {
        display   : flex;
        flex-wrap : wrap;
        margin    : 0 30px;
        text-align: left;
    }

    .driveAdditonalDetails {
        width        : 80%;
        padding      : 0% 10%;
        text-align   : left;
        margin-bottom: 25px;
    }

    .profiletable-header th {
        max-width  : 50px;
        font-size  : 18px;
        font-weight: 400;
    }

    .profiletable tbody tr td {
        height   : 50px;
        max-width: 50px;
    }
}

.resumeIcon {
    height         : 28px;
    width          : 28px;
    margin-left    : 12px;
    border-radius  : 50%;
    color          : black;
    align-items    : center;
    justify-content: center;
    font-size      : 14px;
    background     : var(--six);
    cursor         : pointer;
    position       : relative;
    bottom         : 1px;
    text-decoration: none;
}

.link {
    font-size      : 12px;
    color          : var(--twentyfive);
    text-decoration: underline;
    cursor         : pointer;
}

.sub-container {
    display    : flex;
    width      : 80%;
    margin     : 0 auto;
    padding-top: 10px;
}

.header-republish {
    text-transform: capitalize;
    width         : 30%;
}

.separator {
    width: 20%;
}

.chart-legend .legend-labels {
    float     : none !important;
    text-align: center !important;
}

/* option to remove reveal password optin in microsoft edge browser*/
::-ms-reveal {
    display: none;
}

#chat-editor .ql-container.ql-snow .ql-editor {
    background-color: @white;
    overflow        : scroll;
    max-height      : 9rem;
    font-size       : var(--font-size-medium);
    border          : @px-2 solid transparent;
}

.expand-toolbar .ql-container .ql-editor {
    height: 9rem;
}

#chat-editor .ql-toolbar.ql-snow+.ql-container.ql-snow {
    border-width: 0px;
    border      : none;
}

.ql-container.ql-snow {
    border: none;
}

#chat-editor .ql-mention-list-container,
#chat-editor .ql-mention-list-container .ql-mention-list {
    width: 20.5rem !important;
    left: 0 !important;
}

.list-auto > ul {
    list-style: disc !important;
    padding-left: 15px;
}

.list-auto > ol {
    list-style: auto !important;
    padding-left: 15px;
}

#chat-editor .ql-mention-list-container,
#chat-editor .ql-mention-list-container .ql-mention-list,
#chat-editor .ql-mention-list-container .ql-mention-list .ql-mention-list-item,
#chat-editor .ql-mention-list-container .ql-mention-list .ql-mention-list-item.selected {
    text-overflow: ellipsis;
    white-space  : nowrap;
    overflow     : hidden;
}

.ql-mention-list-item.selected {
    background-color: @primary-color  !important;
    color           : @white;
}
.drive-desc a {
    color: @primary-color !important;
}
// pre {
//     font-family: @font-family;
// }

.ql-snow .ql-editor pre.ql-syntax,
pre.ql-syntax {
    background-color: @black;
    color           : @white;
    width           : max-content !important;
    max-width       : 100%;
    padding         : @px-5 @px-10;
    border-radius   : 0px;
    word-break      : normal;
    overflow-wrap   : break-word;
    overflow-x      : scroll;
}

.ql-editor p {
    width: 100%;
}

.mention {
    color: #FFAD3A;
    font-weight     : @font-weight-medium;
    background-color: @primary-color !important;
}

.p-float-label {
    height: 2.5rem;
}

.p-float-label>label {
    color              : #66666666;
    transition-duration: 0.2s;
    font-size          : var(--font-size-medium);
    font-weight        : 500;
    top                : 50% !important;
    left               : 1.25rem !important;
}

.p-float-label input.p-filled~label,
.p-float-label .p-inputwrapper-filled~label,
.p-float-label input:focus~label,
.p-float-label .p-inputwrapper-focus~label {
    top             : -0.1rem !important;
    left            : 2vh;
    color           : @contrast-color-5 ;
    font-weight     : 500;
    padding         : 3px;
    background-color: white;
}

.p-float-label input:-webkit-autofill~label {
    background-image: linear-gradient(180deg, white 30%, rgb(232, 240, 254) 70%);
}

.p-float-label>.p-dropdown {
    width: 12rem !important;
}

.p-inputtext {
    width        : 100%;
    height       : 100%;
    font-size    : var(--font-size-medium) !important;
    font-family  : inherit !important;
    border       : @px-1 solid @contrast-color-5;
    border-radius: @border-radius-default  !important;
}

.p-inputtext::-webkit-input-placeholder {
    font-weight: 400;
    padding    : 0.75rem !important;
}

.p-dropdown {
    width      : 100%;
    height     : 100%;
    align-items: center;
}
.p-dropdown {
    height: 38px ;
    background: #F7F9FC ;
  }
.dropdown-height .p-dropdown {
    height: 32px !important;
}
  @media (min-width:640px ) {
    .p-dropdown {
      height: 40px ;
    }
    .dropdown-height .p-dropdown {
        height: 32px !important;
    }
  }
.listing .p-dropdown{
    background: #ffffff !important;
}
.p-dropdown-label {
    display    : flex !important;
    align-items: center;
    padding    : 0 10px !important;
}

.p-inputtext {
    color: #060D1A !important;
}

.pi {
    font-size: var(--font-size-medium) !important;
}

.p-multiselect {
    margin-top: 0.5rem;
    width     : 100%;
}

.p-dropdown-trigger {
    width       : 1rem !important;
    margin-right: 0.625rem;
}

.programmingDropdown .p-dropdown-trigger {
    width       : 1rem !important;
    margin-right: 0px;
}

.p-component {
    font-size  : var(--font-size-medium) !important;
    font-family: inherit !important;
}

.p-dropdown-item {
    font-size: var(--font-size-medium) !important;
}

.errorclass {
    border    : @px-1 solid @error-color  !important;
    box-shadow: none !important;
    color     : @error-color;
}

.p-calendar {
    width           : 100%;
    height          : 2.5rem;
    display         : block;
    background-color: #F7F9FC;
}

.otp-form-group {
    display        : flex;
    justify-content: center;
    align-items    : center;
    gap            : @px-15;

    input {
        width        : @px-40 - @px-1;
        padding      : @px-5;
        text-align   : center;
        height       : @px-40 - @px-1;
        border       : @px-1 solid @border-color;
        font-size    : @font-size-medium;
        border-radius: @border-radius-default;
    }
}

.textareaLabel {
    position: relative;
    top     : -8rem;
    left    : 2rem;
    color   : @gray-color;
}

.tot-textarea textarea:focus~label,
.tot-textarea textarea.p-filled~label {
    position        : relative;
    top             : -9.7rem;
    left            : 2rem;
    background-color: @white;
    color           : @contrast-color-5;
}

.p-inputtextarea {
    border-radius: 0.4rem !important;
    color        : @gray-color;
}

.ng2-pdf-viewer-container::-webkit-scrollbar,
.scrollbar-custom::-webkit-scrollbar {
    scrollbar-width: 10px;
}

.ng2-pdf-viewer-container::-webkit-scrollbar,
.scrollbar-custom::-webkit-scrollbar {
    width : 6px;
    height: 6px;
}

.ng2-pdf-viewer-container::-webkit-scrollbar-thumb,
.scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: var(--thirteen);
    outline         : none;
    border-radius   : 3px;
}

.ng2-pdf-viewer-container::-webkit-scrollbar-track,
.scrollbar-custom::-webkit-scrollbar-track {
    background: var(--white);
}

.p-dropdown-items-wrapper::-webkit-scrollbar {
    scrollbar-width: 10px;
}

.sectionDropdown .p-dropdown-items-wrapper {
    max-width: 17rem;
}

.programmingDropdown .p-dropdown-panel {
    max-width: 10rem;
    top      : 30px !important;
}

.sectionDropdown .p-dropdown-panel {
    top: 20px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    overflow     : hidden !important;
    text-overflow: ellipsis !important;
}

.p-dropdown-items-wrapper::-webkit-scrollbar {
    width: 6px;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--thirteen);
    outline         : none;
    border-radius   : 3px;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-track {
    background: var(--white);
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width   : none;
}

.line-clamp {
    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow     : ellipsis;
    overflow          : hidden;
}

.hide-element {
    display: none;
}

.hover-element:hover {
    .hide-element {
        display: flex;
        background: #00000030;
    }
}

.p-dropdown-panel {
    z-index : 1000 !important;
    position: absolute !important;
}

.p-accordion-header-link {
    cursor             : pointer;
    display            : flex;
    align-items        : center;
    -webkit-user-select: none;
    user-select        : none;
    position           : relative;
    text-decoration    : none;
    flex-direction     : row-reverse;
    justify-content    : space-between;
}

#radioBtnID .p-radiobutton-icon {
    width : 8px;
    height: 8px;
}

#radioBtnID .p-radiobutton-box {
    width : 16px;
    height: 16px;
}

#radioBtnID .p-radiobutton {
    width : 16px;
    height: 16px;
}

.p-dropdown:not(.p-disabled):hover {
    box-shadow: 0 0 0 0.2rem rgb(52 86 255 / 10%);
    border    : 1px solid transparent;
}

.footer-overlay::-webkit-scrollbar {
    //    width: 0px;
    display: none;
}

.p-sidebar-bottom {
    border-top-right-radius: 1rem;
    border-top-left-radius : 1rem;
}

.drive-sidebar>.p-sidebar-bottom {
    height    : auto !important;
    max-height: 80vh !important;
}

.p-tabview-nav {
    flex-direction: column;
}

.p-tabview {
    display: flex;
    height : inherit;
}

.p-tabview-nav-container {
    position  : inherit;
    flex-basis: 30.27%;
    max-width : 30.27%;
}

.p-tabview-nav-link {
    padding: 10px 0px 10px 10px !important;
}

.p-tabview-title {
    height       : 20px !important;
    overflow     : hidden !important;
    text-overflow: ellipsis !important;
}

.p-accordion-header {
    border-bottom : 1px solid #d6d6d6;
    padding-bottom: 0.875rem;
}

.p-chip {
    height: 100%;
}
.coeChip .p-chip {
    background: rgba(52, 86, 255, 0.1) ;
    color: rgba(52, 86, 255, 1) ;
    border: 1px solid transparent;
    padding: 10px;
}
.approveChip .p-chip {
    background: rgba(85, 173, 50, 0.1);
    color: rgb(85, 173, 50);
}
.coeChip .p-chip .pi-chip-remove-icon {
    color: rgba(52, 86, 255, 1) ;
    font-size: 14px !important;
}
.coeChip .p-chip .p-chip-text {
    font-size: 14px !important;
}

// .p-dropdown-panel {
//  z-index: 1000 !important;
//  position: absolute !important;
// }

.p-calendar .p-datepicker {
    left: -0.875rem !important;
}

.p-checkbox {
    width : 1rem !important;
    height: 1rem !important;
}

.p-checkbox .p-checkbox-box {
    width        : 1rem !important;
    height       : 1rem !important;
    border       : 1px solid #666666 !important;
    border-radius: 4px !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 8px !important;
}

.policy-check>p-checkbox>.p-checkbox>.p-checkbox-box,
.policy-check>p-checkbox>.p-checkbox {
    width : 1.125rem !important;
    height: 1.125rem !important;
}

@media (max-width: 640px) {

    .p-overlaypanel:after,
    .p-overlaypanel:before {
        top    : 10px !important;
        z-index: -1 !important;
    }

    .p-overlaypanel {
        margin-top: 8px !important;
    }

    .p-overlaypanel .p-overlaypanel-content {
        padding : 0 !important;
        width   : 13rem !important;
        overflow: hidden !important;
    }

    .p-overlaypanel {
        color: #666666;
    }
}

.pi-times-circle:before {
    content: "\e90b" !important;
}

.pi-minus-circle:before {
    content: "\e90f" !important;
}

.pi-plus-circle:before {
    content: "\e90d" !important;
}

.p-component-overlay-enter {
    z-index: 100 !important;
}

.p-datepicker table td {
    padding: 0px;
}

.p-datepicker table td>span {
    padding: 0px;
    width  : 1.5rem !important;
    height : 1.5rem !important;
}

.p-datepicker-header {
    padding: 0 !important;
}

.pi {
    color: #AAAAAA;
}

.mindropheigth .p-dropdown-items-wrapper {
    max-height: 91px !important;
}

.no-cursor .p-radiobutton {
    cursor: not-allowed !important;
}

.no-check-cursor .p-checkbox {
    cursor: not-allowed !important;
}

.profTextBox .p-dropdown .p-dropdown-panel {
    max-width: 100% !important;
    left: 0 !important;
}

.profTextBox .upDrop0 .p-dropdown .p-dropdown-panel {
    margin-top: -165px;
}
.profTextBox .upDrop0 .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
    max-height: 100px !important;
}

.profTextBox .upDrop .p-dropdown .p-dropdown-panel {
    margin-top: -150px;
}
.profTextBox .upDrop .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
    max-height: 100px !important;
}

.profTextBox .upDrop2 .p-dropdown .p-dropdown-panel {
    margin-top: -60px;
}
.profTextBox .upDrop2 .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
    max-height: 100px !important;
}

.profTextBox .upDrop3 .p-dropdown .p-dropdown-panel {
    margin-top: -110px;
}
.profTextBox .upDrop3 .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
    max-height: 100px !important;
}

.profTextBox .p-dropdown .p-dropdown-clear-icon{
    display: none;
}

.margin10 .p-dropdown .p-dropdown-panel {
    margin-top: 10px;
}

.disableCalender .p-calendar .p-inputtext {
    cursor: not-allowed;
}
.p-calendar .p-inputtext {
    cursor: pointer;
}

@media (min-width:1024px) {

    #listPanel>.p-overlaypanel {
        top : 43px !important;
        left: calc(100vw - 44rem) !important;
    }
    #CourseListPanel>.p-overlaypanel {
        top : 50px !important;
        left: calc(100vw - 44rem) !important;
    }
    #DriveListPanel>.p-overlaypanel {
        top : 43px !important;
        left: calc(100vw - 45.3rem) !important;
    }
    #raListPanel>.p-overlaypanel{
        top : 43px !important;
        left: calc(100vw - 23rem) !important;
    }
    #productPanel>.p-overlaypanel {
        top: 43px !important;
    }

    #prodLoginPanel>.p-overlaypanel{
        top: 43px !important;
        // left: calc(100vw - 27rem) !important;
        right: 30px;
        left: auto !important;
    }
}


@media (max-width:400px) {
#chat-editor .ql-mention-list-container,
#chat-editor .ql-mention-list-container .ql-mention-list {
    width: 19.5rem !important;
}
}

@media (max-width:340px) {
    #chat-editor .ql-mention-list-container,
    #chat-editor .ql-mention-list-container .ql-mention-list {
        width: 18.5rem !important;
    }
    } 


@media (max-width:400px) {
#chat-editor .ql-mention-list-container,
#chat-editor .ql-mention-list-container .ql-mention-list {
    width: 19.5rem !important;
}
}

@media (max-width:340px) {
    #chat-editor .ql-mention-list-container,
    #chat-editor .ql-mention-list-container .ql-mention-list {
        width: 18.5rem !important;
    }
    } 

@media (max-width:1023px) {
    #listPanel>.p-overlaypanel {
        top: 43px !important;
        left: 10px;
    }

    #CourseListPanel>.p-overlaypanel {
        top: 50px !important;
        left: 10px;
    }

    #DriveListPanel>.p-overlaypanel {
        top: 43px !important;
        left: 10px;
    }

    #raListPanel>.p-overlaypanel {
        top: 43px !important;
        left: calc(100vw - 20rem) !important;
    }

    #productPanel>.p-overlaypanel {
        top: 43px !important;
    }
}

.background-transperant>p-dropdown>.p-dropdown {
    background: transparent !important;
}

.mindropheigth .custom-dropdown .p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
    padding: 0 !important;
}

.profRadio p-radiobutton .p-radiobutton .p-radiobutton-box {
    border-color: #666666 !important;
}

.btn-loading {
    height : 2.5rem;
    display: block;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: #3456ff !important;
}

.btn-loading:before {
    content      : '';
    height       : 1.25rem;
    width        : 1.25rem;
    display      : inline-block;
    margin       : 0.625rem;
    border       : 0.188rem solid #FFFFFF;
    border-top   : 0.188rem solid #3456FF;
    border-radius: 50%;
    animation    : spin 0.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.radioheight>p-radiobutton>.p-radiobutton>.p-radiobutton-box {
    width : 16px !important;
    height: 16px !important;
}

.radioheight .p-radiobutton {
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.pdf-size .ng2-pdf-viewer-container {
    height: 400px;
}

.detail-tab {
    border-right : 3px solid transparent;
    border-left  : 3px solid transparent;
    border-bottom: 3px solid transparent;
}

.currenttab {
    border-right : 3px solid transparent;
    border-left  : 3px solid transparent;
    border-bottom: 3px solid #3456FF;
    background   : linear-gradient(360deg, rgba(52, 86, 255, 0.4) -134.62%, rgba(52, 86, 255, 0) 100%);
}
.p-datatable-flex-scrollable .p-datatable-wrapper{
    border-radius: 4px;
}

.hide-arrow::-webkit-outer-spin-button,
.hide-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-arrow[type=number] {
  -moz-appearance: textfield;
}
.p-input-icon-left > i.pi.pi-search {
    font-size: 18px !important;
}
.searchInput > input.p-inputtext{
    background: #F7F9FC;
}

p-sidebar#profileInfoSidebarID .p-sidebar-content {
    overflow:hidden;
}

.maxwidth100 .p-dropdown .p-dropdown-panel {
    max-width: 100%;
}
.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0 !important;
}

.ql-editor {
    padding: 12px 5px !important;
}

.placehoder  #chat-editor .ql-container.ql-snow .ql-editor:-ms-input-placeholder {
    font-style: 'normal';
  }
.maxHeightTable .p-datatable-wrapper {
    height: 100%;
    max-height: calc(100vh - 20.5rem);
}

.arrow_box {
	position: relative;
	background-color: rgb(52 86 255 / 5%);
}
.arrow_box:after, .arrow_box:before {
	bottom: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-width: 16px;
	margin-left: -16px;
}
.arrow_box:before {
	border-bottom-color: rgb(52 86 255 / 5%);
	border-width: 22px;
	margin-left: -22px;
}
.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0 !important;
}

.ql-editor {
    padding: 12px 5px !important;
}

.placehoder  #chat-editor .ql-container.ql-snow .ql-editor:-ms-input-placeholder {
    font-style: 'normal';
  }
.growl-text-photoID ul{
    margin-top: 10px;
}

.growl-text-photoID h3{
    margin-top: 10px;
    margin-bottom: 0px;
}
.ql-mention-list-container {
    // top: -400px !important;
    overflow-y: auto !important;
}
.ql-mention-list {
    position: relative !important;
    max-height: 400px !important;
    overflow-y: auto !important;
}
.fileUploadID, .qfileUploadID, .descriptiveImageID {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}
.firstPageHide .pdfViewer [data-page-number="1"] .textLayer {
    opacity: 1;
    backdrop-filter: blur(10px);
}

h1#cert-title {
    font-size: 2em !important;
    font-weight: bold;
}

h2#cert-title {
    font-size: 1.5em !important;
    font-weight: bold;    
}

h3#cert-title {
    font-size: 1.17em !important;
    font-weight: bold;    
}

h4#cert-title {
    font-size: 1em !important;
    font-weight: bold;    
}

h5#cert-title {
    font-size: 0.83em !important;
    font-weight: bold;    
}

h6#cert-title {
    font-size: 0.75em !important;
    font-weight: bold;    
}

@media screen and (max-width: 645px) {
    .account_name_max_width {
        max-width: 100px !important;
    }
}

.truncate_data {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}