@import "../../assets/styles/config.less";

@media screen and (max-width: 768px) {
  .ui.grid>.column:not(.row) {
    padding-top   : 0;
    padding-bottom: 0;
  }
  .title {
    padding-left: @px-10;
  }
  .ui.grid>.column:not(.row),
  .ui.grid>.row>.column {
    padding-left : 0;
    padding-right: 0;
  }

  .not-hide {
    display: block;
  }

  .sidebar {
    width  : 60%;
    display: none;
  }

  .panel-item {
    .item-content {
      display: revert;
    }
  }

  i {
    cursor: pointer;
  }

  .list-card {
    padding: 0;
  }

  .SidebarContent {
    width: 100% !important;
  }

  .list-styles .list-header .ai-logo {
    height: 3.5rem;
  }

  .list-styles .list-header .close-icon {
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .modals.dimmer .ui.scrolling.modal {
    margin    : 0;
    background: @white-bg;
  }

  .ui.fullscreen.modal {
    margin       : 0;
    position     : absolute;
    left         : 0;
    right        : 0;
    top          : 0;
    bottom       : 0;
    width        : 100% !important;
    border-radius: 0;

    .notification-modal {
      border: none;
    }
  }
}

@media only screen and (min-width: 512px) and (max-width: 768px) {
  .sidebar {
    width: 50%;
  }

  .modals.dimmer .ui.c.modal {
    margin    : 0;
    background: @white-bg;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .not-hide {
    display: block;
  }

  .sidebar {
    width  : 20rem;
    display: none;
  }

  .title {
    padding-left: @px-10;
  }

  .icon-split {
    display: flex;
  }

  .resp-modal-view {
    width: 50% !important;
  }

  .list-styles .list-header {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    padding        : 2rem;
    padding-top    : 1rem;
  }

  .list-styles .list-header .ai-logo {
    height: 3.5rem;
  }

  .list-styles .list-header .close-icon {
    cursor: pointer;
  }
}

@media (min-width: 1200px) {
  .close-icon {
    display: none;
  }
}

/*-------------------
     Breakpoints
--------------------*/

@mobileS  :~"(min-width: 330px)";
@mobileM  :~"(min-width: 400px)";
@mobileL  :~"(min-width: 480px)";
@tabletS  :~"(min-width: 600px)";
@tabletL  :~"(min-width: 768px)";
@desktopXS: ~"(min-width: 900px)";
@desktopS :~"(min-width: 1080px)";
@desktopM :~"(min-width: 1200px)";
@desktopL :~"(min-width: 1400px)";